



























import { Getter } from 'vuex-class';
import Vue from 'vue';
import { Component } from 'vue-property-decorator';
import User from '@improve/common-utils/src/model/User';
import Team from '@improve/common-utils/src/model/Team';
import BaseTabOption from '@improve/common-utils/src/types/TabOption';
import BaseUserAvatar from '@improve/common-components/src/components/widgets/BaseUserAvatar.vue';
import BasePageHeader from '@improve/common-components/src/components/widgets/BasePageHeader.vue';
import BaseTabs from '@improve/common-components/src/components/widgets/BaseTabs.vue';

@Component({
  name: 'UserProfile',
  components: {
    BaseUserAvatar,
    BasePageHeader,
    BaseTabs
  }
})
export default class UserProfile extends Vue {
  @Getter currentUser?: User;

  @Getter currentUserUnit?: Team;

  options: Array<BaseTabOption> = [];

  get currentTab(): string {
    return this.$route.name || '';
  }

  created(): void {
    this.initTabs();
  }

  initTabs(): void {
    this.options = [
      {
        title: this.$t('page.userProfile.tabs.myImprovesTab').toString(),
        value: 'MyImprovesTab'
      },
      {
        title: this.$t('page.userProfile.tabs.assignmentsTab').toString(),
        value: 'AssignmentsTab'
      },
      {
        title: this.$t('page.userProfile.tabs.implementedTab').toString(),
        value: 'ImplementedTab'
      },
      {
        title: this.$t('page.userProfile.tabs.teamsTab').toString(),
        value: 'TeamsTab'
      },
      {
        title: this.$t('page.userProfile.tabs.topicsTab').toString(),
        value: 'TopicsTab'
      },
      {
        title: this.$t('page.userProfile.tabs.myProfileTab').toString(),
        value: 'AboutTab'
      }
    ];
  }

  goToTab(tabValue: string): void {
    this.$router.push({ name: tabValue });
  }
}
